import React, { useEffect } from 'react';

import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';
import Help from 'src/pages/Help';
import {
  ContainerMFE,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { HelpAndSupportScreenDisplayed } from 'src/utils/analytics';

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  useEffect(() => {
    publishEvent(HelpAndSupportScreenDisplayed);
  }, []);

  return (
    <DependencyManagerProvider>
      <ContainerMFE data-testid="support-page">
        <Help />
      </ContainerMFE>
    </DependencyManagerProvider>
  );
};

export default App;
