import {
  FeatureFlagKeyValues,
  LookingForSomethingElse,
  SectionHeader,
  useGetFeatureFlags
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useMemo } from 'react';
import FrameCards from 'src/components/FrameCards';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { screenName, screenPath } from 'src/utils/analytics';
import { Header } from './styles';

const Help = () => {
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const featureFlagClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );

  const { originalFeedbackExperience, isLoading } = useGetFeatureFlags({
    getClient: featureFlagClient,
    keys: [FeatureFlagKeyValues.originalFeedbackExperience]
  });

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isLoading;

  return (
    <>
      <Header data-testid="header">
        <SectionHeader
          title={translate('menu.support', 'Help & support')}
          subtitle={translate(
            'support.getSupport',
            'Get support for your HP account and devices'
          )}
        />
      </Header>
      <FrameCards />
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </>
  );
};
export default Help;
